<template>
  <div>
<!--    <b-card>-->
<!--      <liste-filter-->
<!--        :offset1="true"-->
<!--        :fields="filterFields"-->
<!--      />-->
<!--      <div class="text-center">-->
<!--        <b-button-->
<!--          v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
<!--          variant="outline-primary"-->
<!--          class="mr-1 btn_filter"-->
<!--          @click="filter"-->
<!--        >-->
<!--          Filtrer-->
<!--        </b-button>-->
<!--      </div>-->
<!--    </b-card>-->
    <b-card>
      <b-row>
        <b-col
          v-if="chartData.length"
          md="12"
        >
          <b-card title="Nombre de visite distributeur par mois">
            <column-chart3-d
              :data="chartData"
              category-field="mois"
              value-field="nombre_visites"
              series-name="Series 1"
              x-axis-title="Mois"
              y-axis-title="Visites"
            />
          </b-card>
        </b-col>
        <b-col
          v-if="chartData2.length"
          md="12">
          <b-card title="Nombre de visite PDV par mois">
            <column-chart3-d
              :data="chartData2"
              category-field="mois"
              value-field="nombre_visites"
              series-name="Series 1"
              x-axis-title="Mois"
              y-axis-title="Visites"
            />
          </b-card>
        </b-col>
<!--        <b-col md="12">-->
<!--          <b-card title="Nombre de patient par tranche d’âge">-->
<!--            <force-directed-tree-->
<!--              :data="chartData3"-->
<!--              node-title="name"-->
<!--              link-title="count"-->
<!--              link-property="count"-->
<!--              :node-width="Number('50')"-->
<!--              :node-height="Number('50')"-->
<!--            />-->
<!--          </b-card>-->
<!--        </b-col>-->
<!--        <b-col md="12">-->
<!--          <b-card title="Nombre de patient par tranche d’âge">-->
<!--            <donut-Chart-->
<!--              :data="salesData"-->
<!--              title="Ventes mensuelles de produits"-->
<!--              value-field="sales"-->
<!--              category-field="productCategory"-->
<!--            />-->
<!--          </b-card>-->
<!--        </b-col>-->
      </b-row>
    </b-card>
  </div>
</template>

<script>
import ListeFilter from '@/views/m-components/filters/ListeFilterVue.vue'
import Ripple from 'vue-ripple-directive'
import {
  BButton, BCard, BCol, BRow,
} from 'bootstrap-vue'
import ColumnChart3D from '@/views/m-components/charts/ColumnChart3D.vue'
// import ForceDirectedTree from '@/views/m-components/charts/ForceDirectedTree.vue'
// import DonutChart from '@/views/m-components/charts/DonutChart.vue'
import { API_URL } from '@/helpers/global-scops'

export default {
  name: 'MainVue',
  components: {
    // DonutChart,
    // ForceDirectedTree,
    ColumnChart3D,
    ListeFilter,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filterFields: [
        {
          inputId: 'debut',
          label: 'Debut',
          value: '',
          type: 'text',
          placeholder: 'YYYY-MM-DD',
          date: true,
        },
        {
          inputId: 'fin',
          label: 'Fin',
          value: '',
          type: 'text',
          placeholder: 'YYYY-MM-DD',
          date: true,
        },
      ],
      option: {
        xAxis: [
          {
            type: 'category',
            data: ['0-5', '6-10', '11-15', '16-20', '21-30', '31-50', '51 et +'],
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
          },
        ],
        grid: {
          left: '40px',
          right: '30px',
          bottom: '30px',
        },
        series: [
          {
            name: 'Patients',
            type: 'bar',
            stack: 'advertising',
            data: [220, 232, 201, 334, 290, 230, 220],
            barMaxWidth: '30%',
            barMinWidth: '20px',
            itemStyle: {
              color: '#1A83B9',
            },
          },
        ],
      },
      //= =================== ColumnChart3D ====================
      chartData: [
      ],
      // =================== Stracked Column Chart=====================
      chartData2: [],
      seriesData: [
        { seriesName: 'Series 1', valueField: 'value1' },
        { seriesName: 'Series 2', valueField: 'value2' },
        { seriesName: 'Series 3', valueField: 'value3' },
      ],

      //= ============================= Force Directed Tree ====================

      chartData3: [
        {
          name: 'Core',
          children: [
            {
              name: 'First',
              children: [
                { name: 'A1', value: 100 },
                { name: 'A2', value: 60 },
              ],
            },
            {
              name: 'Second',
              children: [
                { name: 'B1', value: 135 },
                { name: 'B2', value: 98 },
              ],
            },
            {
              name: 'Third',
              children: [
                {
                  name: 'C1',
                  children: [
                    { name: 'EE1', value: 130 },
                    { name: 'EE2', value: 87 },
                    { name: 'EE3', value: 55 },
                  ],
                },
                { name: 'C2', value: 148 },
                {
                  name: 'C3',
                  children: [
                    { name: 'CC1', value: 53 },
                    { name: 'CC2', value: 30 },
                  ],
                },
                { name: 'C4', value: 26 },
              ],
            },
            {
              name: 'Fourth',
              children: [
                { name: 'D1', value: 415 },
                { name: 'D2', value: 148 },
                { name: 'D3', value: 89 },
              ],
            },
            {
              name: 'Fifth',
              children: [
                {
                  name: 'E1',
                  children: [
                    { name: 'EE1', value: 33 },
                    { name: 'EE2', value: 40 },
                    { name: 'EE3', value: 89 },
                  ],
                },
                {
                  name: 'E2',
                  value: 148,
                },
              ],
            },

          ],
        },
      ],

      //= =========================== Donut Chart ====================

      salesData: [
        { productCategory: 'Vêtements', sales: 1234 },
        { productCategory: 'Chaussures', sales: 567 },
        { productCategory: 'Accessoires', sales: 890 },
      ],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    filter() {
      console.log(this.filterFields)
    },
    getData() {
      this.$http.get(`${API_URL}main-dashboard/`)
        .then(response => {
          // console.log(response.data)
          this.chartData = response.data.nombre_visites_distributeur_par_mois
          this.chartData2 = response.data.nombre_visites_pdv_par_mois
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>

</style>
